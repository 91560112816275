// shared product icons (FA)
import { faAlien8bit as productVideoGames } from '@fortawesome/pro-solid-svg-icons/faAlien8bit';
// import { faBaby as productBabyClothes } from '@fortawesome/pro-solid-svg-icons/faBaby';
// import { faBackpack as productBackpacks } from '@fortawesome/pro-solid-svg-icons/faBackpack';
// import { faBagShopping as productPurses } from '@fortawesome/pro-solid-svg-icons/faBagShopping';
// import { faBedBunk as productKidsBedroomFurniture } from '@fortawesome/pro-solid-svg-icons/faBedBunk';
import { faBedEmpty as productBedroomFurniture } from '@fortawesome/pro-solid-svg-icons/faBedEmpty';
// import { faBedFront as productMattresses } from '@fortawesome/pro-solid-svg-icons/faBedFront';
// import { faBicycle as productBicycles } from '@fortawesome/pro-solid-svg-icons/faBicycle';
import { faBlanket as productBeddingAndLinens } from '@fortawesome/pro-solid-svg-icons/faBlanket';
import { faBlender as iconBlender } from '@fortawesome/pro-solid-svg-icons/faBlender';
// import { faBlinds as productHomeDecor } from '@fortawesome/pro-solid-svg-icons/faBlinds';
import { faBlueberries as productJuicers } from '@fortawesome/pro-solid-svg-icons/faBlueberries';
// import { faBolt as productPowerTools } from '@fortawesome/pro-solid-svg-icons/faBolt';
// import { faBooks as productBooks } from '@fortawesome/pro-solid-svg-icons/faBooks';
// import { faBoot as productHikingBoots } from '@fortawesome/pro-solid-svg-icons/faBoot';
// import { faBootHeeled as productShoes } from '@fortawesome/pro-solid-svg-icons/faBootHeeled';
// import { faBoothCurtain as productCurtains } from '@fortawesome/pro-solid-svg-icons/faBoothCurtain';
import { faBowlRice as productRiceCookers } from '@fortawesome/pro-solid-svg-icons/faBowlRice';
import { faBreadSlice as productToasters } from '@fortawesome/pro-solid-svg-icons/faBreadSlice';
import { faCamera as productDigitalCameras } from '@fortawesome/pro-solid-svg-icons/faCamera';
import { faCameraSecurity as productVideoDoorbells } from '@fortawesome/pro-solid-svg-icons/faCameraSecurity';
// import { faCampground as productCampingGear } from '@fortawesome/pro-solid-svg-icons/faCampground';
// import { faCandleHolder as productCandles } from '@fortawesome/pro-solid-svg-icons/faCandleHolder';
import { faCar as productCars } from '@fortawesome/pro-solid-svg-icons/faCar';
import { faChair as productFurniture } from '@fortawesome/pro-solid-svg-icons/faChair';
// import { faChairOffice as productOfficeChairs } from '@fortawesome/pro-solid-svg-icons/faChairOffice';
// import { faChessBoard as productBoardGames } from '@fortawesome/pro-solid-svg-icons/faChessBoard';
import { faCoffeePot as productCoffeeMakers } from '@fortawesome/pro-solid-svg-icons/faCoffeePot';
import { faComputer as productDesktopComputers } from '@fortawesome/pro-solid-svg-icons/faComputer';
import { faComputerClassic as productComputers } from '@fortawesome/pro-solid-svg-icons/faComputerClassic';
import { faComputerMouseScrollwheel as productComputerMice } from '@fortawesome/pro-solid-svg-icons/faComputerMouseScrollwheel';
import { faComputerSpeaker as productElectronics } from '@fortawesome/pro-solid-svg-icons/faComputerSpeaker';
import { faCouch as productCouches } from '@fortawesome/pro-solid-svg-icons/faCouch';
import { faDesktop as productGamingPcs } from '@fortawesome/pro-solid-svg-icons/faDesktop';
import { faDiscDrive as productComputerHardware } from '@fortawesome/pro-solid-svg-icons/faDiscDrive';
import { faDisplay as productComputerMonitors } from '@fortawesome/pro-solid-svg-icons/faDisplay';
import { faDna as productDNATestingKits } from '@fortawesome/pro-solid-svg-icons/faDna';
import { faDropletPercent as productDehumidifiers } from '@fortawesome/pro-solid-svg-icons/faDropletPercent';
// import { faDrum as productDrums } from '@fortawesome/pro-solid-svg-icons/faDrum';
import { faDumbbell as productFitnessEquipment } from '@fortawesome/pro-solid-svg-icons/faDumbbell';
import { faEarListen as productEarbuds } from '@fortawesome/pro-solid-svg-icons/faEarListen';
import { faFan as productLawnMowers } from '@fortawesome/pro-solid-svg-icons/faFan';
// import { faFeather as productDownComforters } from '@fortawesome/pro-solid-svg-icons/faFeather';
// import { faFemale as productWeddingDresses } from '@fortawesome/pro-solid-svg-icons/faFemale';
import { faFireBurner as productRangesStovesOrOvens } from '@fortawesome/pro-solid-svg-icons/faFireBurner';
// MUI icons (@todo convert to FA)
// UI icons
import { faFireFlameCurved as iconPopular } from '@fortawesome/pro-solid-svg-icons/faFireFlameCurved';
import { faFrenchFries as productAirFryers } from '@fortawesome/pro-solid-svg-icons/faFrenchFries';
import { faGamepadModern as productVideoGameConsoles } from '@fortawesome/pro-solid-svg-icons/faGamepadModern';
// import { faGlasses as productEyeglasses } from '@fortawesome/pro-solid-svg-icons/faGlasses';
import { faGrillHot as productGrillsAndBBQs } from '@fortawesome/pro-solid-svg-icons/faGrillHot';
// import { faGuitar as productGuitars } from '@fortawesome/pro-solid-svg-icons/faGuitar';
// import { faGuitars as productMusicalInstruments } from '@fortawesome/pro-solid-svg-icons/faGuitars';
// import { faHandSparkles as productSkincareProducts } from '@fortawesome/pro-solid-svg-icons/faHandSparkles';
import { faHeadphones as productHeadphones } from '@fortawesome/pro-solid-svg-icons/faHeadphones';
// import { faHiking as productHikingGear } from '@fortawesome/pro-solid-svg-icons/faHiking';
// import { faHose as productPressureWashers } from '@fortawesome/pro-solid-svg-icons/faHose';
import { faHotTub as productHotTubs } from '@fortawesome/pro-solid-svg-icons/faHotTub';
// default product icon
import { faImage as productDefault } from '@fortawesome/pro-solid-svg-icons/faImage';
// import { faIslandTropical as productHammocks } from '@fortawesome/pro-solid-svg-icons/faIslandTropical';
import { faKeyboard as iconKeyboard } from '@fortawesome/pro-solid-svg-icons/faKeyboard';
// import { faKitchenSet as productKitchenAppliances } from '@fortawesome/pro-solid-svg-icons/faKitchenSet';
// import { faLamp as productLamps } from '@fortawesome/pro-solid-svg-icons/faLamp';
// import { faLampDesk as productOfficeFurniture } from '@fortawesome/pro-solid-svg-icons/faLampDesk';
// import { faLampFloor as productFloorLamps } from '@fortawesome/pro-solid-svg-icons/faLampFloor';
import { faLaptop as productLaptops } from '@fortawesome/pro-solid-svg-icons/faLaptop';
import { faLaptopCode as productMacbooks } from '@fortawesome/pro-solid-svg-icons/faLaptopCode';
import { faLightCeiling as productDiningRoomFurniture } from '@fortawesome/pro-solid-svg-icons/faLightCeiling';
import { faLoveseat as productLivingRoomFurniture } from '@fortawesome/pro-solid-svg-icons/faLoveseat';
// import { faLuggageCart as productTravelGear } from '@fortawesome/pro-solid-svg-icons/faLuggageCart';
// unique product icons (FA)
import { faLungs as productAirPurifiers } from '@fortawesome/pro-solid-svg-icons/faLungs';
// import { faMattressPillow as iconPillowSheets } from '@fortawesome/pro-solid-svg-icons/faMattressPillow';
import { faMicrowave as productMicrowaves } from '@fortawesome/pro-solid-svg-icons/faMicrowave';
import { faMobileAlt as productSmartphones } from '@fortawesome/pro-solid-svg-icons/faMobileAlt';
import { faMobileNotch as productIphones } from '@fortawesome/pro-solid-svg-icons/faMobileNotch';
// import { faMountain as productOutdoorGear } from '@fortawesome/pro-solid-svg-icons/faMountain';
import { faMugTea as productElectricTeaKettles } from '@fortawesome/pro-solid-svg-icons/faMugTea';
import { faOven as productAppliances } from '@fortawesome/pro-solid-svg-icons/faOven';
// import { faPalette as productMakeup } from '@fortawesome/pro-solid-svg-icons/faPalette';
import { faPersonSeatReclined as productRecliners } from '@fortawesome/pro-solid-svg-icons/faPersonSeatReclined';
// import { faPiano as productPianos } from '@fortawesome/pro-solid-svg-icons/faPiano';
import { faPotFood as productSlowCookers } from '@fortawesome/pro-solid-svg-icons/faPotFood';
import { faPrint as productPrinters } from '@fortawesome/pro-solid-svg-icons/faPrint';
// import { faRaygun as productHairDryers } from '@fortawesome/pro-solid-svg-icons/faRaygun';
import { faRefrigerator as productRefrigerators } from '@fortawesome/pro-solid-svg-icons/faRefrigerator';
// import { faRing as productJewelry } from '@fortawesome/pro-solid-svg-icons/faRing';
// import { faRingDiamond as productEngagementRings } from '@fortawesome/pro-solid-svg-icons/faRingDiamond';
import { faRug as iconRug } from '@fortawesome/pro-solid-svg-icons/faRug';
import { faRunning as productFitnessTrackers } from '@fortawesome/pro-solid-svg-icons/faRunning';
// import { faScalpelLineDashed as productWeedWhackersOrWeedEaters } from '@fortawesome/pro-solid-svg-icons/faScalpelLineDashed';
// import { faSeedling as productPlants } from '@fortawesome/pro-solid-svg-icons/faSeedling';
// import { faShip as productCruises } from '@fortawesome/pro-solid-svg-icons/faShip';
// import { faShirtLongSleeve as productWinterJackets } from '@fortawesome/pro-solid-svg-icons/faShirtLongSleeve';
// import { faShower as productBathTowels } from '@fortawesome/pro-solid-svg-icons/faShower';
// import { faSkiBoot as productSkiBoots } from '@fortawesome/pro-solid-svg-icons/faSkiBoot';
// import { faSkiing as iconSkiing } from '@fortawesome/pro-solid-svg-icons/faSkiing';
// import { faSkiingNordic as productSkiJackets } from '@fortawesome/pro-solid-svg-icons/faSkiingNordic';
import { faSnowBlowing as productSnowBlowers } from '@fortawesome/pro-solid-svg-icons/faSnowBlowing';
// import { faSnowflake as productWinterClothes } from '@fortawesome/pro-solid-svg-icons/faSnowflake';
import { faSoap as productDishwashers } from '@fortawesome/pro-solid-svg-icons/faSoap';
// import { faSprayCan as productCologne } from '@fortawesome/pro-solid-svg-icons/faSprayCan';
// import { faSprayCanSparkles as productPerfumes } from '@fortawesome/pro-solid-svg-icons/faSprayCanSparkles';
// import { faSuitcase as productSuitcases } from '@fortawesome/pro-solid-svg-icons/faSuitcase';
// import { faSuitcaseRolling as productLuggage } from '@fortawesome/pro-solid-svg-icons/faSuitcaseRolling';
// import { faSun as productSummerClothes } from '@fortawesome/pro-solid-svg-icons/faSun';
// import { faSunglasses as productSunglasses } from '@fortawesome/pro-solid-svg-icons/faSunglasses';
import { faTabletAlt as productTablets } from '@fortawesome/pro-solid-svg-icons/faTabletAlt';
import { faTabletScreen as productEReaders } from '@fortawesome/pro-solid-svg-icons/faTabletScreen';
import { faTabletScreenButton as productIpads } from '@fortawesome/pro-solid-svg-icons/faTabletScreenButton';
import { faTemperatureSnow as productFreezers } from '@fortawesome/pro-solid-svg-icons/faTemperatureSnow';
// import { faTent as productTents } from '@fortawesome/pro-solid-svg-icons/faTent';
// import { faTire as productTires } from '@fortawesome/pro-solid-svg-icons/faTire';
import { faToiletPortable as productUprightFreezers } from '@fortawesome/pro-solid-svg-icons/faToiletPortable';
// import { faToolbox as productToolChestsOrCabinets } from '@fortawesome/pro-solid-svg-icons/faToolbox';
// import { faTools as productTools } from '@fortawesome/pro-solid-svg-icons/faTools';
import { faTreasureChest as productChestFreezers } from '@fortawesome/pro-solid-svg-icons/faTreasureChest';
import { faTreeChristmas as iconChristmasTree } from '@fortawesome/pro-solid-svg-icons/faTreeChristmas';
import { faTruckPickup as productTrucks } from '@fortawesome/pro-solid-svg-icons/faTruckPickup';
// import { faTshirt as productClothing } from '@fortawesome/pro-solid-svg-icons/faTshirt';
import { faTvRetro as productTV } from '@fortawesome/pro-solid-svg-icons/faTvRetro';
import { faUmbrellaBeach as productPatioFurniture } from '@fortawesome/pro-solid-svg-icons/faUmbrellaBeach';
// import { faUserHairBuns as productHaircareProducts } from '@fortawesome/pro-solid-svg-icons/faUserHairBuns';
import { faVacuum as iconVacuum } from '@fortawesome/pro-solid-svg-icons/faVacuum';
import { faVacuumRobot as productRobotVacuums } from '@fortawesome/pro-solid-svg-icons/faVacuumRobot';
// import { faWalking as productTreadmills } from '@fortawesome/pro-solid-svg-icons/faWalking';
import { faWasher as productWashersAndDryers } from '@fortawesome/pro-solid-svg-icons/faWasher';
import { faWatchApple as productAppleWatches } from '@fortawesome/pro-solid-svg-icons/faWatchApple';
// import { faWind as productHairTools } from '@fortawesome/pro-solid-svg-icons/faWind';
import {
  CheckCircleOutlineRounded as IconBuy,
  CalendarMonthRounded as IconMonthly,
  AddAlertRounded as IconNotify,
  LinkRounded as IconPermalink,
  LoyaltyRounded as IconRarity,
  ReadMoreRounded as IconReadMore,
  TodayRounded as IconToday,
} from '@mui/icons-material';
// unique product icons (MUI)
// import ProductBabyFurniture from '@mui/icons-material/CribRounded';
import ProductElectricScooters from '@mui/icons-material/ElectricScooterRounded';
// share product icons (MUI)
import IconTable from '@mui/icons-material/TableRestaurantRounded';
import { SvgIcon } from '@mui/material';
import React, { isValidElement } from 'react';

import FAIcon from '../components/FAIcon';
// custom icons
import ProductMicrosoftXbox from '../images/icons/icon-microsoft-xbox.svg';
import ProductNintendoSwitch from '../images/icons/icon-nintendo-switch.svg';
import ProductSonyPlayStation from '../images/icons/icon-sony-playstation.svg';

// make FontAwesome certain icons ready to use (primarily UI icons)
const IconHolidays = () => <FAIcon icon={iconChristmasTree} />;

const productIcons = {
  'air-fryers': productAirFryers,
  'air-purifiers': productAirPurifiers,
  'apple-watches': productAppleWatches,
  appliances: productAppliances,
  'area-rugs': iconRug,
  'artificial-christmas-trees': iconChristmasTree,
  // 'baby-clothes': productBabyClothes,
  // 'baby-furniture': <ProductBabyFurniture />,
  // backpacks: productBackpacks,
  // 'bath-towels': productBathTowels,
  // 'bed-sheets': iconPillowSheets,
  'bedding-and-linens': productBeddingAndLinens,
  'bedroom-furniture': productBedroomFurniture,
  // bicycles: productBicycles,
  blenders: iconBlender,
  // 'board-games': productBoardGames,
  // books: productBooks,
  // 'camping-gear': productCampingGear,
  // candles: productCandles,
  'carpet-cleaners': iconVacuum,
  cars: productCars,
  'chest-freezers': productChestFreezers,
  // clothing: productClothing,
  'coffee-makers': productCoffeeMakers,
  'coffee-tables': <IconTable />,
  // cologne: productCologne,
  'computer-hardware': productComputerHardware,
  'computer-keyboards': iconKeyboard,
  'computer-mice': productComputerMice,
  'computer-monitors': productComputerMonitors,
  computers: productComputers,
  couches: productCouches,
  // cruises: productCruises,
  // curtains: productCurtains,
  dehumidifiers: productDehumidifiers,
  'desktop-computers': productDesktopComputers,
  'digital-cameras': productDigitalCameras,
  'dining-room-furniture': productDiningRoomFurniture,
  dishwashers: productDishwashers,
  'dna-testing-kits': productDNATestingKits,
  // 'down-comforters': productDownComforters,
  // drums: productDrums,
  'e-readers': productEReaders,
  earbuds: productEarbuds,
  'electric-scooters': <ProductElectricScooters />,
  'electric-tea-kettles': productElectricTeaKettles,
  electronics: productElectronics,
  // 'engagement-rings': productEngagementRings,
  // eyeglasses: productEyeglasses,
  'fitness-equipment': productFitnessEquipment,
  'fitness-trackers': productFitnessTrackers,
  // 'floor-lamps': productFloorLamps,
  'food-processors': iconBlender,
  freezers: productFreezers,
  furniture: productFurniture,
  'gaming-keyboards': iconKeyboard,
  'gaming-pcs': productGamingPcs,
  'grills-and-bbqs': productGrillsAndBBQs,
  // guitars: productGuitars,
  // 'hair-dryers': productHairDryers,
  // 'hair-tools': productHairTools,
  // 'haircare-products': productHaircareProducts,
  // hammocks: productHammocks,
  headphones: productHeadphones,
  // 'hiking-boots': productHikingBoots,
  // 'hiking-gear': productHikingGear,
  // 'home-decor': productHomeDecor,
  'hot-tubs': productHotTubs,
  ipads: productIpads,
  iphones: productIphones,
  // jewelry: productJewelry,
  juicers: productJuicers,
  // 'kids-bedroom-furniture': productKidsBedroomFurniture,
  // 'kitchen-appliances': productKitchenAppliances,
  // lamps: productLamps,
  laptops: productLaptops,
  'lawn-mowers': productLawnMowers,
  'living-room-furniture': productLivingRoomFurniture,
  // luggage: productLuggage,
  macbooks: productMacbooks,
  // makeup: productMakeup,
  // mattresses: productMattresses,
  'microsoft-xbox': <ProductMicrosoftXbox />,
  microwaves: productMicrowaves,
  // 'musical-instruments': productMusicalInstruments,
  'nintendo-switch': <ProductNintendoSwitch />,
  // 'office-chairs': productOfficeChairs,
  // 'office-furniture': productOfficeFurniture,
  // 'outdoor-gear': productOutdoorGear,
  'patio-furniture': productPatioFurniture,
  // perfumes: productPerfumes,
  // pianos: productPianos,
  // pillowcases: iconPillowSheets,
  // plants: productPlants,
  'sony-playstation': <ProductSonyPlayStation />,
  // 'power-tools': productPowerTools,
  // 'pressure-washers': productPressureWashers,
  printers: productPrinters,
  // purses: productPurses,
  'ranges-stoves-or-ovens': productRangesStovesOrOvens,
  recliners: productRecliners,
  refrigerators: productRefrigerators,
  'rice-cookers': productRiceCookers,
  'robot-vacuums': productRobotVacuums,
  // rugs: iconRug,
  // shoes: productShoes,
  'shop-vacs-or-wet-dry-vacuums': iconVacuum,
  // 'side-or-end-tables': <IconTable />,
  // 'ski-boots': productSkiBoots,
  // 'ski-gear': iconSkiing,
  // 'ski-jackets': productSkiJackets,
  // 'skincare-products': productSkincareProducts,
  // skis: iconSkiing,
  'slow-cookers': productSlowCookers,
  smartphones: productSmartphones,
  'snow-blowers': productSnowBlowers,
  // suitcases: productSuitcases,
  // 'summer-clothes': productSummerClothes,
  // sunglasses: productSunglasses,
  tablets: productTablets,
  // tents: productTents,
  // tires: productTires,
  toasters: productToasters,
  // 'tool-chests-or-cabinets': productToolChestsOrCabinets,
  // tools: productTools,
  // 'travel-gear': productTravelGear,
  // treadmills: productTreadmills,
  trucks: productTrucks,
  tv: productTV,
  'upright-freezers': productUprightFreezers,
  'vacuum-cleaners': iconVacuum,
  'video-doorbells-or-doorbell-cameras': productVideoDoorbells,
  'video-game-consoles': productVideoGameConsoles,
  'video-games': productVideoGames,
  'washers-and-dryers': productWashersAndDryers,
  // 'wedding-dresses': productWeddingDresses,
  // 'weed-whackers-or-weed-eaters': productWeedWhackersOrWeedEaters,
  // 'winter-clothes': productWinterClothes,
  // 'winter-jackets': productWinterJackets,
};

const PRODUCT_ICON_CLASSNAME = 'product-icon';

// get an icon by product ID
const getProductIcon = (productId) => {
  const icon = productIcons[productId];

  // if no icon is specified, return the default icon
  if (!icon) {
    return <FAIcon icon={productDefault} />;
  }

  // if this is a React element, use SVGIcon
  if (isValidElement(icon)) {
    return <SvgIcon className={PRODUCT_ICON_CLASSNAME}>{icon}</SvgIcon>;
  }

  // else, this is a FontAwesome icon
  if (typeof icon === 'object') {
    return <FAIcon icon={icon} />;
  }

  // this should never happen, but just in case...
  return (
    <FAIcon
      className={PRODUCT_ICON_CLASSNAME}
      icon={productDefault}
    />
  );
};

export {
  getProductIcon,
  iconPopular,
  IconBuy,
  IconHolidays,
  IconMonthly,
  IconNotify,
  IconPermalink,
  IconRarity,
  IconReadMore,
  IconToday,
};
